window.SchemesPlugin = {
    install: function (Vue, options) {
        var schemesRequests = (options.schemes || [])
            .map(function (scheme) {
                return window.axios.get(scheme.file)
                    .then(function (result) {
                        scheme.data = result.data
                            .replace(/<\?.*?\?>/, '')
                            .replace(/g id="cabin-/g, 'g v-cabin="');
                        return scheme;
                    });
            });

        var SchemeComponent = function (schemes) {
            return {
                template: '<scheme :scheme="scheme" :options="options" v-if="enable"></scheme>',
                props: {
                    name: {
                        type: String,
                        required: true
                    },
                    selected: {
                        type: Array,
                        default: [],
                    },
                    available: {
                        type: Array,
                        default: null,
                    },
                    statuses: {
                        type: Object,
                        default: null,
                    },
                    selectionQuantity: {
                        type: Number,
                        default: 1,
                    },
                    selectionEnabled: {
                        type: Boolean,
                        default: true,
                    },
                },
                data: function () {
                    return {
                        enable: true,
                    }
                },
                updated: function () {
                    this.enable = true;
                },
                computed: {
                    scheme: function () {
                        var name = this.name;
                        var scheme = schemes.find(function (item) {
                            return item.name === name;
                        });
                        if (scheme) {
                            return scheme.data;
                        }
                        return null;
                    },
                    options: function () {
                        return {
                            selected: this.selected,
                            available: this.available,
                            statuses: this.statuses,
                            selectionQuantity: this.selectionQuantity,
                            selectionEnabled: this.selectionEnabled,
                        };
                    }
                },
                watch: {
                    name: function () {
                        this.enable = false;
                    },
                },
                components: {
                    'scheme': {
                        props: {
                            scheme: {
                                type: String,
                                default: null,
                            },
                            options: {
                                type: Object,
                                default: {},
                            }
                        },
                        data: function () {
                            return {
                                selected: [],
                                available: [],
                                statuses: {},
                                selectionQuantity: 0,
                                selectionEnabled: false,
                            };
                        },
                        render: function () {
                            return this.renderer();
                        },
                        computed: {
                            renderer: function () {
                                return Vue.compile(this.scheme).render;
                            }
                        },
                        methods: {
                            select: function (value) {
                                this.$parent.$emit('click', value);
                                if (this.selectionEnabled && (!this.available || this.available.includes(value))) {
                                    this.toggleSelected(value);
                                }
                            },
                            toggleSelected: function (number) {
                                var index = this.selected.indexOf(number);
                                if (index > -1) {
                                    this.selected.splice(index, 1);
                                } else {
                                    this.selected.push(number);
                                }
                                var quantity = this.selectionQuantity;
                                if (quantity) {
                                    this.selected = this.selected.slice(-quantity);
                                }
                            },
                        },
                        watch: {
                            selected: function (value) {
                                this.$parent.$emit('select', value);
                            },
                            options: {
                                immediate: true,
                                handler: function (value) {
                                    this.selected = value.selected;
                                    this.available = value.available;
                                    this.statuses = value.statuses;
                                    this.selectionQuantity = value.selectionQuantity;
                                    this.selectionEnabled = value.selectionEnabled;
                                }
                            },
                        },
                        directives: {
                            cabin: {
                                bind: function (element, binding, vnode) {
                                    element.addEventListener('click', function () {
                                        vnode.context.select(binding.value);
                                    }, false);
                                    setClasses(element, binding.value, vnode.context);
                                },
                                componentUpdated: function (element, binding, vnode) {
                                    setClasses(element, binding.value, vnode.context);
                                },
                            }
                        }
                    }
                }
            }
        };

        function setClasses(element, value, context) {
            element.className = "";
            element.classList.add("cabin");
            if (context.selected.includes(value)) {
                element.classList.add("selected");
            } else {
                element.classList.remove("selected");
            }
            if (context.available && !context.available.includes(value)) {
                element.classList.add("disabled");
            } else {
                element.classList.remove("disabled");
            }
            if (context.statuses) {
                var status = context.statuses[value];
                if (status) {
                    element.classList.add(status);
                }
            }
        }

        Vue.component('deck-scheme', function (resolve) {
            Promise.all(schemesRequests)
                .then(function (schemes) {
                    resolve(new SchemeComponent(schemes));
                });
        });
    }
};